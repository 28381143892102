<template>
   <section class="cont">
   <!--   面包屑    -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/order_manage' }">订单列表</el-breadcrumb-item>
            <el-breadcrumb-item>订单详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button  type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="basic-info" v-if="show">
         <el-row class="title"><b>订单信息</b></el-row>
         <el-row class="basic-top">
           <el-col :span="12">
             <el-row class="m-bottom-10">
               <p><span>订单编号：</span>{{ data.orderNo || "无信息"}}</p>
               <p><span>下单时间：</span>{{ data.createTime }}</p>
             </el-row>
             <!-- <el-row>
                <p><span>支付状态：</span>{{  }}</p>
             </el-row> -->
           </el-col>
           <el-col :span="12">
             <el-row class="m-bottom-10">
               <p><span>订单类型：</span>{{ data.orderType | orderTypeFilter(that) }} <span style="margin-left: 20px">所属酒店：</span>{{this.$route.query.hotelName}}</p>
               <!-- <p><span>支付时间：</span>{{tableData[0].certificateNo}}</p> -->
             </el-row>
             <el-row>
               <p><span>订单状态：</span>{{ data.orderStatus | orderStatusFilter(that) }}</p>
             </el-row>
           </el-col>
         </el-row>
       </el-row>
       <el-row class="basic-info">
         <el-row class="title"><b>会员信息</b></el-row>
         <el-row class="basic-top">
           <el-col :span="12">
             <el-row class="m-bottom-10">
               <p><span>会员编号：</span>{{ data.member.id }}</p>
               <p><span>手机号码：</span>{{ data.member.mobile }}</p>
             </el-row>
             <el-row>
               <p><span>注册时间：</span>{{ data.member.createTime }}</p>
             </el-row>
           </el-col>
           <el-col :span="12">
             <el-row class="m-bottom-10">
               <p><span>会员昵称：</span>{{ data.member.nickName }}</p>
               <p v-if="data.hasOwnProperty('gender')"><span>性别：</span>{{ data.member.gender | filterGender }}</p>
             </el-row>
           </el-col>
         </el-row>
       </el-row>
       <!-- <el-row class="basic-info">
          <el-row class="title"><b>收货信息</b></el-row>
          <el-row class="basic-top">
             <el-col :span="12">
                <el-row class="m-bottom-10">
                   <p><span>收货人：</span>{{  }}</p>
                   <p><span>收货地址：</span>{{  }}</p>
                </el-row>
             </el-col>
             <el-col :span="12">
                <el-row class="m-bottom-10">
                   <p><span>手机号：</span>{{tableData[0].mobile}}</p>
                </el-row>
             </el-col>
          </el-row>
       </el-row> -->
       <el-row class="basic-info" v-if="data.orderType !== 'HOTEL'">
         <el-row class="title"><b>商品信息</b></el-row>
         <el-row class="basic-top">
           <el-table
               ref="mainTable"
               :data="[data]"
               :stripe="true">
             <el-table-column label="商品信息" min-width="150">
               <template slot-scope="scope">
                 <el-row class="member-info">
                   <img :src="scope.row.member.headPortrait" alt="">
                   <el-row>
                     <p>商品编号：{{ scope.row.member.id }}</p>
                     <p>商品名称：{{ scope.row.member.nickName }}</p>
                     <p>商品类型：{{ scope.row.orderType | orderTypeFilter(that) }}</p>
                   </el-row>
                 </el-row>
               </template>
             </el-table-column>
             <el-table-column label="价格（元）" min-width="140">
               <template slot-scope="scope">
                 <p>￥{{ scope.row.orderTotalPrice }}</p>
               </template>
             </el-table-column>
             <el-table-column prop="orderStatus" label="数量">
               <template slot-scope="scope">
                 <p>{{ scope.row.orderItems && scope.row.orderItems.length }}</p>
               </template>
             </el-table-column>
             <el-table-column label="小计">
               <template slot-scope="scope">
                 <p>{{ scope.row.orderTotalPrice }}</p>
               </template>
             </el-table-column>
           </el-table>
           <el-row>
             <el-col :span="3">商品金额:{{ data.orderTotalPrice }}</el-col>
             <el-col :span="3">运费金额:￥0.00</el-col>
             <el-col :span="3">优惠金额:￥0.00</el-col>
           </el-row>
           <el-row>
             <el-col :span="3">支付方式:{{ data.paymentMethod | payFilter(that) }}</el-col>
             <el-col :span="3">应付金额:￥{{ data.orderTotalPrice }}</el-col>
           </el-row>
         </el-row>
       </el-row>
       <el-row class="basic-info">
         <el-row class="title"><b>买家留言</b></el-row>
         <el-row class="basic-top">
           <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
         </el-row>
       </el-row>
      <!-- <el-row class="basic-info">
         <el-row class="title"><b>订单日志</b></el-row>
         <el-row class="basic-top">
            <el-table
                ref="mainTable"
                :data="tableData"
                :stripe="true"
                >
               <el-table-column label="日期" min-width="150">
                  <template slot-scope="scope">
                     <el-row class="member-info">
                        <img :src="scope.row.member.headPortrait" alt="">
                        <el-row>
                           <p>会员编号：{{ scope.row.member.id }}</p>
                           <p>会员昵称：{{ scope.row.member.nickName }}</p>
                           <p>会员等级：{{ scope.row.member.level }}</p>
                        </el-row>
                     </el-row>
                  </template>
               </el-table-column>
               <el-table-column label="动作类型" min-width="140">
                  <template slot-scope="scope">
                     <p>￥{{ scope.row.orderNo }}</p>
                  </template>
               </el-table-column>
               <el-table-column prop="orderStatus" label="日志内容"></el-table-column>
            </el-table>
         </el-row>
      </el-row> -->
   </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import {getDict} from '@/common/js/common'
import {order} from "@/api/interface/business";
import { mapState } from 'vuex'
export default {
   data(){
      return{
         that: this,
         data: { member: {} },
         show: false,
         textarea: ""
      }
   },
   computed:{
      ...mapState(['dictData'])
   },
   created(){
      getDict(['gender', 'order-status', 'order-source', 'order-type', 'payment-method'])
      this.getOrder()
   },
   methods: {
      // 返回上页
      goBack(){
         this.$router.go(-1)
      },
      // 查询订单详情
      getOrder(){
         const url = order.getOrder + `?page=${1}&limit=${1}`
         const param = { orderNo: this.$route.query.orderNo }
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.tableLoad = false
            this.show = true
            if (res.records.length === 0) return;
            this.data = res.records[0]

         })
      },
   },
   filters:{
      filterSource(val, that){
         if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || ''
      },
      filterGender(val, that) {
         if (val && that.dictData['gender']) return that.dictData['gender'][val] || ''
      },
      orderStatusFilter(val, that){
         if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || "未获取订单信息"
      },
      orderTypeFilter(val, that){
         if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || "未获取订单类型"
      },
      payFilter(val, that){
         if (val && that.dictData['payment-method']) return that.dictData['payment-method'][val] || val
      }
   }
}
</script>

<style scoped lang="scss">
.title{
   border-bottom: #e5e6e6 solid 1px; padding: 25px 0 10px 20px;
   font-size: 16px;
}
/* 基本信息 */
.basic-info{
   background: #FFFFFF;
   box-shadow: 0 0 8px #DCE7F9; padding: 0 0 20px 0; color: #222222;
   margin: 0 0 10px 0;
   span{ color: #9c9c9c }
   .basic-top{
      padding: 20px 100px; line-height: 30px;
   }
}
</style>

